import { render, staticRenderFns } from "./MailName.vue?vue&type=template&id=e48d801e&scoped=true&"
import script from "./MailName.vue?vue&type=script&lang=js&"
export * from "./MailName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e48d801e",
  null
  
)

export default component.exports
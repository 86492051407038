<template>
  <div class="mail-text mail-block" :style="{backgroundColor: backgroundColor}">
    <div class="dragger"></div>
    <mail-nav
      @delete="$emit('delete')"
      @edit="editContent"
      @up="$emit('up')"
      @down="$emit('down')"
    ></mail-nav>
    <div class="mail-content">
      <badge>Обычный текст</badge>
      <p class="mail__input" v-html="content" v-if="!showEditor"></p>
      <vue-editor
        v-model="content"
        :editorToolbar="customToolbar"
        class="wsywig"
        v-else
      ></vue-editor>
      <mail-common-settings>
        <color-selector @color="setBackgroundColor" :start-color="backgroundColor"></color-selector>
        <width-selector @width="setWidth" :start-width="width" ></width-selector>
      </mail-common-settings>
      <button
        @click="saveContent"
        class="btn-custom btn-primary-custom"
        v-if="showEditor"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import { block } from "@/mixins/block.js";
import { color } from "@/mixins/color.js";
import { width } from "@/mixins/width.js";
import MailCommonSettings from "@/components/MailCommonSettings";

export default {
  name: "MailText",
  components: {MailCommonSettings},
  mixins: [block, color, width],
  props: ["html"],
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.content = this.html;
  },
};
</script>

<style lang="scss" scoped>
.mail__input {
  font-family: "Avenir Next Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em !important;
  color: #121212;
}
</style>

<template>
  <div class="mail-h1 mail-block" :style="{backgroundColor: backgroundColor}">
    <div class="dragger"></div>
    <mail-nav
        @delete="$emit('delete')"
        @edit="editContent"
        @up="$emit('up')"
        @down="$emit('down')"
    ></mail-nav>
    <div class="mail-content">
      <badge>Дни рождения</badge>
      <div
          v-for="(man, index) in people"
          :key="index"
          class="man"
      >
        <div
            class="man__controls"
            v-if="people.length > 1 && showEditor"
        >
          <action-btn
              size="small"
              action="up"
              @up="moveMan(man, index, 'up')"
          ></action-btn>
          <button
              class="man__round-btn man__delete-btn"
              @click="deleteMan(index)"
          />
          <action-btn
              size="small"
              action="down"
              @down="moveMan(man, index, 'down')"
          ></action-btn>
        </div>
        <div class="man__field">
          <p class="man__label">Укажите дату рождения:</p>
          <input v-model="man.date" class="man__input content__input" :disabled="!showEditor" placeholder="Введите дату рождения"/>
        </div>
        <div class="man__field">
          <p class="man__label">Укажите имя:</p>
          <input v-model="man.name" class="man__input content__input" :disabled="!showEditor" placeholder="Введите имя"/>
        </div>
      </div>
      <button
          @click="addMan"
          class="button__primary man__add-btn"
          v-if="showEditor"
      >
        Добавить именинника
      </button>
      <mail-common-settings>
        <color-selector @color="setBackgroundColor" :start-color="backgroundColor"></color-selector>
        <width-selector @width="setWidth" :start-width="width"></width-selector>
      </mail-common-settings>
      <button
          @click="saveContent"
          class="btn-custom btn-primary-custom"
          v-if="showEditor"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {block} from "@/mixins/block.js";
import {color} from "@/mixins/color.js";
import {width} from "@/mixins/width.js";
import ActionBtn from "@/components/ActionBtn.vue";
import MailCommonSettings from "@/components/MailCommonSettings";

export default {
  name: "MailBirthdays",
  mixins: [block, color, width],
  components: {
    MailCommonSettings,
    ActionBtn,
  },
  props: ['parameters'],
  data() {
    return {
      people: [],
    };
  },
  created() {
    this.people = this.parameters.people;
  },
  methods: {
    deleteMan(index) {
      this.people.splice(index, 1)
    },
    moveMan(elementToMove, indexToMove, direction) {
      const indexToReplace =
          direction === "up" ? indexToMove - 1 : indexToMove + 1;
      const elementToReplace = this.people[indexToReplace];

      //условие для предотвращения ошибок при перемещении вверх-вниз при остуствующих элементах рядом
      if (direction === "up") {
        if (indexToReplace >= 0) {
          Vue.set(this.people, indexToMove, elementToReplace);
          Vue.set(this.people, indexToReplace, elementToMove);
        }
      } else if (direction === "down") {
        if (indexToReplace < this.people.length) {
          Vue.set(this.people, indexToMove, elementToReplace);
          Vue.set(this.people, indexToReplace, elementToMove);
        }
      }
    },
    addMan() {
      this.people.push({
        date: '',
        name: '',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.man {
  $control-col-width: 30px;
  position: relative;
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  padding: 6px 12px;
  box-shadow: $shadow-grey;
  width: calc(100% - #{$control-col-width});

  &__field {
    flex-basis: 50%;
  }

  &__label {
    font-size: 12px;
  }

  &__input {
    margin-bottom: 0px;
    font-size: 12px;
  }

  &__controls {
    position: absolute;
    right: -$control-col-width;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__round-btn {
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: $shadow-grey;
    cursor: pointer;
    border: none;
  }

  &__delete-btn {
    background-image: url("~@/assets/trash.svg");
    background-size: 16px 16px;
    background-position: center;
    margin: auto;
  }

  &__add-btn {
    margin-bottom: 16px;
  }
}
</style>

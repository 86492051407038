<template>
  <div class="mail-h1 mail-block" :style="{backgroundColor: backgroundColor}">
    <div class="dragger"></div>
    <mail-nav
        @delete="$emit('delete')"
        @edit="editContent"
        @up="$emit('up')"
        @down="$emit('down')"
    ></mail-nav>
    <div class="mail-content">
      <badge>Команда</badge>
      <template v-for="(employee, index) in employees">
        <mail-team-employee
            :key="employee.id"
            :parameters="{
            employee,
            showControls: employees.length > 1 && showEditor,
            showEditor,
          }"
            :image-url="employee.image"
            @image="employee.file = $event"
            @clear-image="employee.image = $event"
            @delete="deleteEmployee(index)"
            @move="moveEmployee(employee, index, ...arguments)"
        />
      </template>
      <button
          @click="addEmployee"
          class="button__add add-btn"
          v-if="showEditor"
      />
      <mail-common-settings>
        <color-selector @color="setBackgroundColor" :start-color="backgroundColor"></color-selector>
        <width-selector @width="setWidth" :start-width="width" ></width-selector>
      </mail-common-settings>
      <button
          @click="saveContent"
          class="btn-custom btn-primary-custom"
          v-if="showEditor"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {block} from "@/mixins/block.js";
import {color} from "@/mixins/color.js";
import {width} from "@/mixins/width.js";
import MailTeamEmployee from "@/components/MailTeamEmployee";
import MailCommonSettings from "@/components/MailCommonSettings";

export default {
  name: "MailTeam",
  mixins: [block, color, width],
  components: {
    MailCommonSettings,
    MailTeamEmployee,
  },
  props: ['parameters'],
  data() {
    return {
      idCounter: 1,
      employees: [],
      maxSize: "10MB",
    };
  },
  created() {
    this.employees = this.parameters.employees;
  },
  methods: {
    deleteEmployee(index) {
      this.employees.splice(index, 1);
    },
    moveEmployee(elementToMove, indexToMove, direction) {
      const indexToReplace =
          direction === "up" ? indexToMove - 1 : indexToMove + 1;
      const elementToReplace = this.employees[indexToReplace];

      //условие для предотвращения ошибок при перемещении вверх-вниз при остуствующих элементах рядом
      if (direction === "up") {
        if (indexToReplace >= 0) {
          Vue.set(this.employees, indexToMove, elementToReplace);
          Vue.set(this.employees, indexToReplace, elementToMove);
        }
      } else if (direction === "down") {
        if (indexToReplace < this.employees.length) {
          Vue.set(this.employees, indexToMove, elementToReplace);
          Vue.set(this.employees, indexToReplace, elementToMove);
        }
      }
    },
    addEmployee() {
      this.idCounter++;
      this.employees.push({
        id: this.idCounter,
        name: '',
        position: '',
        file: "",
        tasks: [{
          text: '',
        }],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  margin-bottom: 12px;
}
</style>

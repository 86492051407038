<template>
  <div class="settings">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MailCommonSettings",
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  align-items: center;
  gap: 12px;
}
</style>

import WidthSelector from "@/components/WidthSelector.vue";

export const width = {
  components: {
    WidthSelector,
  },
  data() {
    return {
      width: "600",
    };
  },
  methods: {
    setWidth(event) {
      this.width = event;
      this.$emit("width", this.width);
    },
  },
};

<template>
  <div class="width-selector">
    <span>Кол-во колонок:</span>
    <div
      v-for="width in widths"
      :key="width.id"
      class="width-selector__item"
      :class="{selected: width.width == selected}"
      @click="selectWidth(width.width)"
    >
      {{width.id}}
    </div>
  </div>
</template>

<script>
export default {
  name: "WidthSelector",
  props: ['startWidth'],
  data() {
    return {
      widths: [
        {
          width: "600",
          id: 10,
        },
        {
          width: "480",
          id: 8,
        },
      ],
      selected: "600",
    };
  },
  methods: {
    selectWidth(width) {
      this.selected = width;
      this.$emit('width', width);
    },
    setWidth(width) {
      this.selected = width;
    }
  },
  mounted() {
    this.$parent.$on('set-width', this.setWidth)
    this.selected = this.startWidth
  },
  beforeDestroy() {
    this.$parent.$off('set-width', this.setWidth)
  }
};
</script>

<style lang="scss" scoped>
.width-selector {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  width: auto;
  margin-bottom: 1rem;
  font-size: 14px;

  &__item {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $black;
    margin: 0 5px;
    cursor: pointer;

    &.selected {
      border: 2px solid $blue;
    }
  }
}
</style>

<template>
  <div
      class="employee"
  >
    <div
        class="employee__controls"
        v-if="parameters.showControls"
    >
      <action-btn size="small" action="up" @up="$emit('move', 'up')"></action-btn>
      <button
          class="employee__round-btn employee__delete-btn"
          @click="$emit('delete')"
      />
      <action-btn size="small" action="down" @down="$emit('move', 'down')"></action-btn>
    </div>
    <VueFileAgent
        class="mail-fileinput employee__fileinput"
        ref="vueFileAgent"
        :theme="'list'"
        :multiple="false"
        :deletable="true"
        :meta="true"
        :accept="'image/*,.zip'"
        :maxSize="maxSize"
        :maxFiles="14"
        :helpText="'Перетащите файлы сюда или нажмите для загрузки'"
        :errorText="{
          type: 'Неверный типа файла',
          size: `Файл не должен быть больше ${maxSize}`,
        }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
        v-model="fileRecords"
    >
    </VueFileAgent>
    <div class="employee__field">
      <p class="employee__label">Укажите имя:</p>
      <input
          v-model="employee.name"
          class="employee__input content__input"
          :disabled="!parameters.showEditor"
          placeholder="Введите имя"
      />
    </div>
    <div class="employee__field">
      <p class="employee__label">Укажите должность:</p>
      <input
          v-model="employee.position"
          class="employee__input content__input"
          :disabled="!parameters.showEditor"
          placeholder="Введите должность"
      />
    </div>
    <div
        v-for="(task, index) in employee.tasks"
        :key="index"
        class="task"
    >
      <input
          v-model="task.text"
          class="task__input content__input"
          :disabled="!parameters.showEditor"
          placeholder="Описание"
      />
      <button
          class="task__delete-btn"
          @click="deleteTask(index)"
          v-if="parameters.showEditor"
      />
    </div>
    <button
        @click="addTask"
        class="button__primary task__add-btn"
        v-if="parameters.showEditor"
    >
      Добавить пункт
    </button>
  </div>
</template>

<script>
import {block} from "@/mixins/block.js";
import {color} from "@/mixins/color.js";
import {width} from "@/mixins/width.js";
import ActionBtn from "@/components/ActionBtn.vue";
import {fileLoader} from "@/mixins/file-loader";

export default {
  name: "MailTeamEmployee",
  mixins: [fileLoader, block, color, width],
  components: {
    ActionBtn,
  },
  props: ['parameters'],
  data() {
    return {
      employee: {},
      maxSize: "10MB",
    };
  },
  created() {
    this.employee = this.parameters.employee;
  },
  methods: {
    addTask() {
      this.employee.tasks.push({
        text: '',
      });
    },
    deleteTask(index) {
      this.employee.tasks.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.employee {
  $control-col-width: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
  padding: 6px 12px;
  box-shadow: $shadow-grey;
  width: calc(100% - #{$control-col-width});

  &__fileinput {
    width: 100%;

    &.mail-fileinput {
      margin-bottom: 0;
    }
  }

  &__field {
    width: calc(50% - #{$control-col-width/2});
  }

  &__label {
    margin-bottom: 5px;
    font-size: 12px;
  }

  &__input {
    margin-bottom: 0px;
    font-size: 12px;
  }

  &__controls {
    position: absolute;
    right: -$control-col-width;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__round-btn {
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: $shadow-grey;
    cursor: pointer;
    border: none;
  }

  &__delete-btn {
    background-image: url("~@/assets/trash.svg");
    background-size: 16px 16px;
    background-position: center;
    margin: auto;
  }
}

.task {
  $control-col-width: 30px;
  position: relative;
  display: flex;
  gap: 12px;
  width: 100%;

  &__input {
    margin-bottom: 0px;
    font-size: 12px;
  }

  &__controls {
    position: absolute;
    right: -$control-col-width;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__delete-btn {
    background-color: white;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: $shadow-grey;
    cursor: pointer;
    border: none;
    background-image: url("~@/assets/trash.svg");
    background-size: 16px 16px;
    background-position: center;
    margin: auto;
  }

  &__add-btn {
    margin: 0 auto;
  }
}
</style>
